import getConfig from '@/configs/global';
import api from '@/helpers/strapi/api';

const config = getConfig();
const { publicRuntimeConfig } = config;

const { CMS_URL } = publicRuntimeConfig;

export const getAbsoluteImageUrl = relativeUrl => {
  if (!relativeUrl) return null;

  return new URL(relativeUrl, CMS_URL).toString();
};

export const fetchAllPages = async () => {
  const query = {
    pagination: {
      page: 1,
      pageSize: 100,
    },
  };

  const request = api.getPages(query);
  const requests = [request];

  const { data } = await request;
  const { page, pageCount } = data.meta.pagination;

  // `index` should start from the next/second page not to duplicate items
  for (let index = page + 1; index <= pageCount; index += 1) {
    const nextPageRequest = await api.getPages({
      ...query,
      pagination: { ...query.pagination, page: index },
    });

    requests.push(nextPageRequest);
  }

  const responses = await Promise.all(requests);

  return responses.reduce(
    (result, current) => [...result, ...current.data.data],
    []
  );
};

export const fetchAllBlogPosts = async () => {
  const query = {
    pagination: {
      page: 1,
      pageSize: 100,
    },
    populate: {
      blogCategory: {
        populate: {
          metadata: {
            populate: '*',
          },
        },
      },
      metadata: {
        populate: '*',
      },
    },
  };

  const request = await api.getBlogPosts(query);
  const requests = [request];

  const { data } = await request;
  const { page, pageCount } = data.meta.pagination;

  // `index` should start from the next/second page not to duplicate items
  for (let index = page + 1; index <= pageCount; index += 1) {
    const nextPageRequest = await api.getBlogPosts({
      ...query,
      pagination: { ...query.pagination, page: index },
    });

    requests.push(nextPageRequest);
  }
  const response = await Promise.all(requests);

  return response.reduce(
    (result, current) => [...result, ...current.data.data],
    []
  );
};
